import { CONSTANTS } from '../helpers/variables';
import axiosService from './axios.service';


class AuthService {
  async login(user) {
     await axiosService.login(CONSTANTS.APIURL + 'login', {
      email: user.email,
      password: user.password
    })
  }

  logout() {
    localStorage.removeItem('access-token');
    localStorage.removeItem('access-role');
    localStorage.removeItem('access-email');
    window.location.replace(CONSTANTS.BASEURL);
  }

  // register(user) {
  //   return api.post(API_URL + 'signup', {
  //     username: user.username,
  //     email: user.email,
  //     password: user.password
  //   });
  // }
}

export default new AuthService();