import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: "/",
      name: "login",
      component: () => import("@/view/pages/authentication/login"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/dashboards",
      name: "dashboards",
      component: () => import("@/view/pages/dashboards"),
      meta: {
        title: "แดชบอร์ด",
        breadcrumb: [
          {
            text: "Dashboards"
          }
        ]
      },
    },
    {
      path: "/pages/settings/users",
      name: "users",
      component: () => import("@/view/pages/settings/users"),
      meta: {
        title: "ผู้ใช้งาน",
        breadcrumb: [
          {
            text: "Settings"
          }
        ]
      },
    },
    {
      path: "/pages/users/settings/add-user",
      name: "add-user",
      component: () => import("@/view/pages/settings/users/add-user"),
      meta: {
        title: "เพิ่มผู้ใช้งาน",
        breadcrumb: [
          {
            text: "Settings"
          }
        ]
      },
    },
    {
      path: "/pages/users/settings/edit-user/:id",
      name: "edit-user",
      component: () => import("@/view/pages/settings/users/edit-user"),
      meta: {
        title: "แก้ไขผู้ใช้งาน",
        breadcrumb: [
          {
            text: "Settings"
          }
        ]
      },
    },
    {
      path: "/pages/personnel/experts",
      name: "experts",
      component: () => import("@/view/pages/personnel/experts"),
      meta: {
        title: "ผู้ทรงคุณวุฒิ",
        breadcrumb: [
          {
            text: "Personnel"
          }
        ]
      },
    },
    {
      path: "/pages/personnel/experts/add-expert",
      name: "add-expert",
      component: () => import("@/view/pages/personnel/experts/add-expert"),
      meta: {
        title: "เพิ่มผู้ทรงคุณวุฒิ",
        breadcrumb: [
          {
            text: "Personnel"
          }
        ]
      },
    },
    {
      path: "/pages/personnel/experts/edit-expert/:id",
      name: "edit-expert",
      component: () => import("@/view/pages/personnel/experts/edit-expert"),
      meta: {
        title: "แก้ไขผู้ทรงคุณวุฒิ",
        breadcrumb: [
          {
            text: "Personnel"
          }
        ]
      },
    },
    {
      path: "/pages/personnel/inspectors",
      name: "inspectors",
      component: () => import("@/view/pages/personnel/inspectors"),
      meta: {
        title: "ผู้ทรงประจำกองบรรณาธิการ",
        breadcrumb: [
          {
            text: "Personnel"
          }
        ]
      },
    },
    {
      path: "/pages/personnel/inspectors/add-inspector",
      name: "add-inspector",
      component: () => import("@/view/pages/personnel/inspectors/add-inspector"),
      meta: {
        title: "เพิ่มผู้ทรงประจำกองบรรณาธิการ",
        breadcrumb: [
          {
            text: "Personnel"
          }
        ]
      },
    },
    {
      path: "/pages/personnel/inspectors/edit-inspector/:id",
      name: "edit-inspector",
      component: () => import("@/view/pages/personnel/inspectors/edit-inspector"),
      meta: {
        title: "แก้ไขผู้ทรงประจำกองบรรณาธิการ",
        breadcrumb: [
          {
            text: "Personnel"
          }
        ]
      },
    },
    {
      path: "/pages/personnel/authors",
      name: "authors",
      component: () => import("@/view/pages/personnel/authors"),
      meta: {
        title: "ผู้เขียน",
        breadcrumb: [
          {
            text: "Personnel"
          }
        ]
      },
    },
    {
      path: "/pages/personnel/inspectors/add-author",
      name: "add-author",
      component: () => import("@/view/pages/personnel/authors/add-author"),
      meta: {
        title: "เพิ่มผู้เขียน",
        breadcrumb: [
          {
            text: "Personnel"
          }
        ]
      },
    },
    {
      path: "/pages/personnel/authors/edit-author/:id",
      name: "edit-author",
      component: () => import("@/view/pages/personnel/authors/edit-author"),
      meta: {
        title: "แก้ไขผู้เขียน",
        breadcrumb: [
          {
            text: "Personnel"
          }
        ]
      },
    },
    {
      path: "/pages/articles/publishes",
      name: "publishes",
      component: () => import("@/view/pages/articles/publishes"),
      meta: {
        title: "ประเภทเผยแพร่",
        breadcrumb: [
          {
            text: "Publishes"
          }
        ]
      },
    },
    {
      path: "/pages/articles/publishes/add-publish",
      name: "add-publish",
      component: () => import("@/view/pages/articles/publishes/add-publish"),
      meta: {
        title: "ประเภทเผยแพร่",
        breadcrumb: [
          {
            text: "Publishes"
          }
        ]
      },
    },
    {
      path: "/pages/articles/publishes/edit-publish/:id",
      name: "edit-publish",
      component: () => import("@/view/pages/articles/publishes/edit-publish"),
      meta: {
        title: "ประเภทเผยแพร่",
        breadcrumb: [
          {
            text: "Publishes"
          }
        ]
      },
    },
    {
      path: "/pages/commentation/comments",
      name: "comments",
      component: () => import("@/view/pages/commentation/comments"),
      meta: {
        title: "ความคิดเห็น",
        breadcrumb: [
          {
            text: "Commentation"
          }
        ]
      },
    },
    {
      path: "/pages/commentation/comments/add-comment",
      name: "add-comment",
      component: () => import("@/view/pages/commentation/comments/add-comment"),
      meta: {
        title: "ความคิดเห็น",
        breadcrumb: [
          {
            text: "Commentation"
          }
        ]
      },
    },
    {
      path: "/pages/commentation/comments/edit-comment/:id",
      name: "edit-comment",
      component: () => import("@/view/pages/commentation/comments/edit-comment"),
      meta: {
        title: "ความคิดเห็น",
        breadcrumb: [
          {
            text: "Commentation"
          }
        ]
      },
    },
    {
      path: "/pages/articles/categories",
      name: "article-categories",
      component: () => import("@/view/pages/articles/categories"),
      meta: {
        title: "หมวดหมู่บทความ",
        breadcrumb: [
          {
            text: "Articles"
          }
        ]
      },
    },
    {
      path: "/pages/articles/categories/add-category",
      name: "article-add-category",
      component: () => import("@/view/pages/articles/categories/add-category"),
      meta: {
        title: "เพิ่มหมวดหมู่บทความ",
        breadcrumb: [
          {
            text: "Articles"
          }
        ]
      },
    },
    {
      path: "/pages/articles/categories/edit-category/:id",
      name: "article-edit-category",
      component: () => import("@/view/pages/articles/categories/edit-category"),
      meta: {
        title: "แก้ไขหมวดหมู่บทความ",
        breadcrumb: [
          {
            text: "Articles"
          }
        ]
      },
    },
    {
      path: "/pages/articles/types",
      name: "article-types",
      component: () => import("@/view/pages/articles/types"),
      meta: {
        title: "ประเภทบทความ",
        breadcrumb: [
          {
            text: "Articles"
          }
        ]
      },
    },
    {
      path: "/pages/articles/types/add-type",
      name: "article-add-type",
      component: () => import("@/view/pages/articles/types/add-type"),
      meta: {
        title: "เพิ่มประเภทบทความ",
        breadcrumb: [
          {
            text: "Articles"
          }
        ]
      },
    },
    {
      path: "/pages/articles/types/edit-type/:id",
      name: "article-edit-type",
      component: () => import("@/view/pages/articles/types/edit-type"),
      meta: {
        title: "แก้ไขประเภทบทความ",
        breadcrumb: [
          {
            text: "Articles"
          }
        ]
      },
    },
    {
      path: "/pages/articles/status",
      name: "article-status",
      component: () => import("@/view/pages/articles/status"),
      meta: {
        title: "สถานะบทความ",
        breadcrumb: [
          {
            text: "Status"
          }
        ]
      },
    },
    {
      path: "/pages/articles/status/add-status",
      name: "add-status",
      component: () => import("@/view/pages/articles/status/add-status"),
      meta: {
        title: "เพิ่มสถานะบทความ",
        breadcrumb: [
          {
            text: "Status"
          }
        ]
      },
    },
    {
      path: "/pages/articles/status/edit-status/:id",
      name: "edit-status",
      component: () => import("@/view/pages/articles/status/edit-status"),
      meta: {
        title: "แก้ไขสถานะบทความ",
        breadcrumb: [
          {
            text: "Status"
          }
        ]
      },
    },
    {
      path: "/pages/payments/institution",
      name: "institutions",
      component: () => import("@/view/pages/payments/institution"),
      meta: {
        title: "หน่วยงาน",
        breadcrumb: [
          {
            text: "institutions"
          }
        ]
      },
    },
    {
      path: "/pages/payments/institution/add-institution",
      name: "add-institution",
      component: () => import("@/view/pages/payments/institution/add-institution"),
      meta: {
        title: "เพิ่มหน่วยงาน",
        breadcrumb: [
          {
            text: "institutions"
          }
        ]
      },
    },
    {
      path: "/pages/payments/institution/edit-institution/:id",
      name: "edit-institution",
      component: () => import("@/view/pages/payments/institution/edit-institution"),
      meta: {
        title: "แก้ไขหน่วยงาน",
        breadcrumb: [
          {
            text: "institutions"
          }
        ]
      },
    },
    {
      path: "/pages/journals/journal",
      name: "journals",
      component: () => import("@/view/pages/journals/journal"),
      meta: {
        title: "วารสาร",
        breadcrumb: [
          {
            text: "journals"
          }
        ]
      },
    },
    {
      path: "/pages/journals/journal/add-journal",
      name: "add-journal",
      component: () => import("@/view/pages/journals/journal/add-journal"),
      meta: {
        title: "เพิ่มเล่มวารสาร",
        breadcrumb: [
          {
            text: "journals"
          }
        ]
      },
    },
    {
      path: "/pages/journals/journal/edit-journal/:id",
      name: "edit-journal",
      component: () => import("@/view/pages/journals/journal/edit-journal"),
      meta: {
        title: "แก้ไขเล่มวารสาร",
        breadcrumb: [
          {
            text: "journals"
          }
        ]
      },
    },
    {
      path: "/pages/forms/article-form",
      name: "article-form",
      component: () => import("@/view/pages/forms/article-form"),
      meta: {
        title: "ตารางบทความ",
        breadcrumb: [
          {
            text: "Forms"
          }
        ]
      },
    },
    {
      path: "/pages/forms/article-form/add-article-form",
      name: "add-article-form",
      component: () => import("@/view/pages/forms/article-form/add-article-form"),
      meta: {
        title: "เพิ่มบทความ",
        breadcrumb: [
          {
            text: "Forms"
          }
        ]
      },
    },
    {
      path: "/pages/forms/article-form/edit-article-form/:id",
      name: "edit-article-form",
      component: () => import("@/view/pages/forms/article-form/edit-article-form"),
      meta: {
        title: "แก้ไขบทความ",
        breadcrumb: [
          {
            text: "Forms"
          }
        ]
      },
    },
    {
      path: "/pages/forms/initial-assessment-form",
      name: "initial-assessment-form",
      component: () => import("@/view/pages/forms/initial-assessment-form"),
      meta: {
        title: "ตารางแบบฟอร์มกลั่นกรองต้นฉบับบทความวิจัยเบื้องต้นโดยกองบรรณาธิการ ",
        breadcrumb: [
          {
            text: "Forms"
          }
        ]
      },
    },
    {
      path: "/pages/forms/add-assessment-form/:id",
      name: "add-assessment-form",
      component: () => import("@/view/pages/forms/initial-assessment-form/add-assessment-form"),
      meta: {
        title: "เพิ่มแบบฟอร์มกลั่นกรองต้นฉบับบทความวิจัยเบื้องต้นโดยกองบรรณาธิการ",
        breadcrumb: [
          {
            text: "Forms"
          }
        ]
      },
    },
    {
      path: "/pages/forms/edit-assessment-form/:id",
      name: "edit-assessment-form",
      component: () => import("@/view/pages/forms/initial-assessment-form/edit-assessment-form"),
      meta: {
        title: "แก้ไขแบบฟอร์มกลั่นกรองต้นฉบับบทความวิจัยเบื้องต้นโดยกองบรรณาธิการ ",
        breadcrumb: [
          {
            text: "Forms"
          }
        ]
      },
    },
    {
      path: "/pages/payments/receipts",
      name: "receipts",
      component: () => import("@/view/pages/payments/receipts"),
      meta: {
        title: "ตารางใบเสร็จรับเงิน",
        breadcrumb: [
          {
            text: "Receipts"
          }
        ]
      },
    },
    {
      path: "/pages/payments/receipts/add-receipt",
      name: "add-receipt",
      component: () => import("@/view/pages/payments/receipts/add-receipt"),
      meta: {
        title: "เพิ่มใบเสร็จรับเงิน",
        breadcrumb: [
          {
            text: "Receipts"
          }
        ]
      },
    },
    {
      path: "/pages/payments/receipts/edit-receipt/:id",
      name: "edit-receipt",
      component: () => import("@/view/pages/payments/receipts/edit-receipt"),
      meta: {
        title: "แก้ไขใบเสร็จรับเงิน",
        breadcrumb: [
          {
            text: "Receipts"
          }
        ]
      },
    },
    {
      path: "/pages/error-404",
      name: "error-404",
      component: () => import("@/view/pages/errors/404"),
      meta: {
        layout: "full",
      },
    },
    {
      path: '*',
      component: () => import("@/view/pages/errors/404"),
      meta: {
        layout: "full",
      },
    },
  ]
})

export default router

// getUsers : async function(){
//   let users = await userService.getUsers();
//   let usersData = users.data.users;
//   this.items = usersData;

//   console.log("element",this.items);
// },