import axios from "axios";
import { CONSTANTS } from "../helpers/variables";
import Toastify from 'toastify-js'

axios.defaults.headers.common['Authorization'] = localStorage.getItem('access-token');
const clientApi = axios.create({
  baseURL: 'https://feujournal.com/backend/public/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
});


class AxiosService {
  async get(...url) {
   try {
      if (localStorage.getItem("access-token")) {
        return await clientApi.get(...url, {
          headers: {
            Authorization: localStorage.getItem('access-token')
          }
        });
      } else {
        localStorage.removeItem('access-token');
        window.location.replace(CONSTANTS.BASEURL);
      }
    } catch (error) {
      localStorage.removeItem('access-token');
      window.location.replace(CONSTANTS.BASEURL);
    }
  }
  async login(...url) {
      await clientApi.post(...url).then((response)=>{
        if (response.data.token) {
          let bearer = "Bearer ";
          localStorage.setItem('access-token', bearer.concat(response.data.token));
          localStorage.setItem('access-role', response.data.role);
          localStorage.setItem('access-email', response.data.email);
        } 
      })
  }
  async post(...url) {
    try {
      if (localStorage.getItem("access-token")) {
        await clientApi.post(...url, {
          headers: {
            Authorization: localStorage.getItem('access-token')
          }
        });
       Toastify({
         text: "การเพิ่มข้อมูลข้อมูลสำเร็จ",
         duration: 3000,
         newWindow: true,
         close: true,
         gravity: "top", // `top` or `bottom`
         position: "right", // `left`, `center` or `right`
         stopOnFocus: true, // Prevents dismissing of toast on hover
         style: {
           background: "#198754",
           color : "#fff",
           borderColor: "#198754"
         },
         onClick: function(){} // Callback after click
       }).showToast();
     } else {
      //  localStorage.removeItem('access-token');
      //  window.location.replace(CONSTANTS.BASEURL);
     }
    } catch (error) {
      Toastify({
        text: "การเปลี่ยนแปลงข้อมูลไม่สำเร็จ",
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: "#dc3545",
          color : "#fff",
          borderColor: "#dc3545"
        },
        onClick: function(){} // Callback after click
      }).showToast();
      // localStorage.removeItem('access-token');
      // window.location.replace(CONSTANTS.BASEURL);
    }
    
  }
  async put(...url) {
    try {
      if (localStorage.getItem("access-token")) {
        await clientApi.put(...url, {
          headers: {
            Authorization: localStorage.getItem('access-token')
          }
        });
        Toastify({
          text: "การเปลี่ยนแปลงข้อมูลสำเร็จ",
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "#198754",
            color : "#fff",
            borderColor: "#198754"
          },
          onClick: function(){} // Callback after click
        }).showToast();
      } else {
        localStorage.removeItem('access-token');
        window.location.replace(CONSTANTS.BASEURL);
      }
    } catch (error) {
      Toastify({
        text: "การเปลี่ยนแปลงข้อมูลไม่สำเร็จ",
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: "#dc3545",
          color : "#fff",
          borderColor: "#dc3545"
        },
        onClick: function(){} // Callback after click
      }).showToast();
      localStorage.removeItem('access-token');
      window.location.replace(CONSTANTS.BASEURL);
    }
   
  }
  async detete(...url) {
    try {
      if (localStorage.getItem("access-token")) {
        await clientApi.delete(...url), {
          headers: {
            Authorization: localStorage.getItem('access-token')
          }
        };
        Toastify({
          text: "การลบข้อมูลข้อมูลสำเร็จ",
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "#198754",
            color : "#fff",
            borderColor: "#198754"
          },
          onClick: function(){} // Callback after click
        }).showToast();
      } else {
        localStorage.removeItem('access-token');
        window.location.replace(CONSTANTS.BASEURL);
      }
    } catch (error) {
      Toastify({
        text: "การเปลี่ยนแปลงข้อมูลไม่สำเร็จ",
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: "#dc3545",
          color : "#fff",
          borderColor: "#dc3545"
        },
        onClick: function(){} // Callback after click
      }).showToast();
      localStorage.removeItem('access-token');
      window.location.replace(CONSTANTS.BASEURL);
    }
    }
   
  }

export default new AxiosService();