export const CONSTANTS = {
    APIURL : "https://feujournal.com/backend/public/api/",
    BASEURL : "https://feujournal.com/"
    // APIURL: "http://localhost:8081/api/",
    // BASEURL: "http://localhost:8082/"
}

export const COLORSTONE1 = [
    "#e615a6", "#a8a247", "#20bc8d", "#39ac46", "#deb53d", "#e69da0", "#29aa16",
    "#f88a9e", "#2a903d", "#a2b888", "#5a0c75", "#8268a4", "#4f4e1a", "#fc13a9",
    "#b22967", "#bfff6f", "#0c2a8c", "#a61e9e", "#a56999", "#3ec876", "#32c948",
    "#d872e5", "#8a7f60", "#76a3dc", "#c143f1", "#bb5719", "#5efb33", "#683790",
    "#9868c9", "#69c13f", "#ed9db5", "#bbc931", "#1e67de", "#b0e034", "#e8c034",
    "#61fad1", "#115b28", "#a9ae0a", "#ec3c63", "#578308", "#943776", "#b2c4ef",
    "#edefde", "#88ea34", "#7c6193", "#ca7665", "#b8476c", "#a07cbe", "#d432fa",
    "#b1217e"
];

export const COLORSTONE2 = [
    "#d472a2", "#f0a939", "#7f0d3c", "#2b80c7", "#b8a62a", "#3c7d53", "#d14b6f",
    "#5ab29c", "#cb754d", "#83f1ad", "#3b57c3", "#76acb2", "#de395c", "#39af87",
    "#7348e1", "#ba5e7e", "#1e9b3a", "#f0ba6f", "#91235d", "#4d25bc", "#ee6f54",
    "#69c2ee", "#8e75b5", "#c0f4d8", "#243c83", "#fb905b", "#6dcb38", "#e8c26b",
    "#907bce", "#452f8e", "#87eaaf", "#d05a9b", "#a67d49", "#ce56d7", "#3a6c83",
    "#deab78", "#f6b1de", "#7042da", "#9dc567", "#5191e4", "#bea84a", "#ee9461",
    "#c1356b", "#7bdecf", "#e878d4", "#6e93c1", "#83bd59", "#ea6f34", "#d236a7",
    "#48ce9b"
];

